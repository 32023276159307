import GatsbyImage from "gatsby-image"
import styled from "styled-components"

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

interface Props {
  node?: ImageNode
  src?: string // fallback for front-end requests
  alt?: string
  title?: string
  style?: React.CSSProperties
  className?: string
}

const Image = ({ node, src, ...props }: Props) => {
  if (node) {
    if (node.childImageSharp) {
      const { fixed, fluid } = node.childImageSharp
      if (fixed) {
        return <GatsbyImage fixed={fixed} {...props} />
      }
      return <GatsbyImage fluid={fluid} {...props} />
    }
    if (node.publicURL) {
      src = node.publicURL
    }
  }
  if (src) {
    return (
      <div className="gatsby-image-wrapper fallback" {...props}>
        <Img src={src} />
      </div>
    )
  }
  return null
}

export default Image
