import { COLORS, LAYOUT, SIZES } from "src/constants"
import styled from "styled-components"

export const Grid = styled.div`
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
`
export const Block = styled.div<{ $col: 2 | 3 }>`
  width: calc(${({ $col }) => ($col === 3 ? "33.33%" : "50%")} - 20px);
  min-width: 280px;
  margin: 10px;

  @media (max-width: ${LAYOUT.width - 1}px) {
    width: 100%;
  }
`
export const Section = styled.section`
  max-width: ${LAYOUT.width}px;
  margin: auto;
  padding: 0 20px;
`
export const HomeSection = styled(Section)`
  padding-top: 10px;
  padding-bottom: 40px;
`
export const Odd = styled.div`
  background-color: ${COLORS.bright};
`

export const Subtitle = styled.h2`
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 0 8px 0;
`
export const Item = styled.li`
  margin-bottom: 10px;
  & > p {
    font-size: ${SIZES.small}px;
    margin: 5px 0 0;
    svg {
      vertical-align: -2px;
      margin-right: 2px;
    }
  }
`
