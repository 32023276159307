import { lighten } from "polished"
import { useMemo } from "react"
import { DefaultTheme } from "styled-components"
import { COLORS } from "../constants"

declare module "styled-components" {
  interface DefaultTheme {
    fontFamily: string
    headerBgUrl?: string
    footerBgColor: HtmlColor
    footerFgColor: HtmlColor
    primaryColor: HtmlColor
    primaryTextColor: HtmlColor
    primaryHighlightedColor: HtmlColor
    primaryTextHighlightedColor: HtmlColor
    textColor: HtmlColor
    headerFgColor: HtmlColor
  }
}

export const defaultTheme: DefaultTheme = {
  fontFamily: '"Nunito Sans", sans-serif',
  headerBgUrl: "url(/bg.jpg)",
  footerBgColor: COLORS.grey,
  footerFgColor: COLORS.white,
  primaryColor: COLORS.blue,
  primaryHighlightedColor: COLORS.blue,
  primaryTextColor: COLORS.white,
  primaryTextHighlightedColor: COLORS.white,
  textColor: COLORS.black,
  headerFgColor: COLORS.black,
}

export const getThemeFromDomainTheme = (theme?: DomainTheme | null): DefaultTheme => {
  return {
    fontFamily: theme?.font || defaultTheme.fontFamily,
    headerBgUrl: theme?.header_bg_url ? `url(${theme.header_bg_url})` : defaultTheme.headerBgUrl,
    footerBgColor: theme?.footer_bg_color || defaultTheme.footerBgColor,
    footerFgColor: theme?.footer_fg_color || defaultTheme.footerFgColor,
    primaryColor: theme?.primary_color || defaultTheme.primaryColor,
    primaryHighlightedColor: lighten(0.1, theme?.primary_color || defaultTheme.primaryHighlightedColor),
    primaryTextColor: theme?.primary_text_color || defaultTheme.primaryTextColor,
    primaryTextHighlightedColor: lighten(0.1, theme?.primary_text_color || defaultTheme.primaryTextColor),
    textColor: theme?.text_color || defaultTheme.textColor,
    headerFgColor: theme?.header_fg_color || defaultTheme.headerFgColor,
  }
}

export const useThemeFromDomainTheme = (theme: DomainTheme): DefaultTheme => {
  return useMemo(() => getThemeFromDomainTheme(theme), [theme])
}
