import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Section } from "src/components/Grid"
import { LAYOUT } from "src/constants"
import Assolib from "src/svg/assolib.svg"
import Facebook from "src/svg/facebook.svg"
import Instagram from "src/svg/instagram.svg"
import Twitter from "src/svg/twitter.svg"
import World from "src/svg/world.svg"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const Container = styled.footer`
  background-color: ${(props) => props.theme.footerBgColor};
  color: ${(props) => props.theme.footerFgColor};
`
const Content = styled(Section)`
  padding: 20px;
  text-align: center;
  hr {
    width: 60px;
    height: 2px;
    border: none;
    background-color: white;
    opacity: 0.4;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  h2 {
    margin: 10px 0;
  }
  h2,
  a {
    color: ${(props) => props.theme.footerFgColor};
  }
`
const Columns = styled.div`
  @media (min-width: ${LAYOUT.tablet}px) {
    display: flex;
    justify-content: space-around;
  }
`
const SocialNetworks = styled.div`
  a {
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    svg {
      vertical-align: middle;
      fill: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.footerFgColor};
      width: 30px;
      height: 29px;
    }
  }
`

const Entry = ({ to, title }: { to: string; title: string }) => (
  <li>
    <Link to={to} activeClassName="active">
      {title}
    </Link>
  </li>
)

interface FooterProps {
  domain?: Domain // It can be undefined, e.g. 404 page
}

const Footer = ({ domain }: FooterProps) => {
  return (
    <Container>
      <Content>
        <Columns>
          <div>
            <h2>Suivez-nous !</h2>
            <SocialNetworks>
              {domain?.facebook_url && (
                <OutboundLink href={domain.facebook_url} target="_blank" rel="noopener">
                  <Facebook />
                </OutboundLink>
              )}
              {domain?.twitter_url && (
                <OutboundLink href={domain.twitter_url} target="_blank" rel="noopener">
                  <Twitter />
                </OutboundLink>
              )}
              {domain?.instagram_url && (
                <OutboundLink href={domain.instagram_url} target="_blank" rel="noopener">
                  <Instagram />
                </OutboundLink>
              )}
              <OutboundLink href={domain?.outlink_url} target="_blank" rel="noopener">
                <World />
              </OutboundLink>
            </SocialNetworks>
          </div>
          <div>
            <h2>Plan du site</h2>
            <ul>
              <Entry to="/" title="Accueil" />
              <Entry to="/associations/" title="Associations" />
              <OutboundLink href="https://back-office.assolib.fr/" target="_blank" rel="noopener">
                Accès associations
              </OutboundLink>
              {domain?.legal_notice_url && (
                <>
                  <li>&nbsp;</li>
                  <OutboundLink href={domain.legal_notice_url} target="_blank" rel="noopener">
                    Mentions légales
                  </OutboundLink>
                </>
              )}
            </ul>
          </div>
          <div>
            <h2>Propulsé par</h2>
            <OutboundLink href="https://assolib.fr/" target="_blank" rel="noopener">
              <Assolib />
            </OutboundLink>
          </div>
        </Columns>
      </Content>
    </Container>
  )
}

export default Footer
