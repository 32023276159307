import { graphql, Link, useStaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { useEffect, useState } from "react"
import { Section } from "src/components/Grid"
import Hamburger from "src/components/Hamburger"
import Image from "src/components/Image"
import { COLORS, LAYOUT, SIZES } from "src/constants"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

const HEIGHT = {
  mobile: 50,
  desktop: 80,
}

const Container = styled.header`
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 3;
  position: relative;
`
const Content = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: ${HEIGHT.mobile}px;
  @media (min-width: ${LAYOUT.tablet}px) {
    height: ${HEIGHT.desktop}px;
    button {
      display: none;
    }
  }
  @media (max-width: ${LAYOUT.tablet - 1}px) {
    .gatsby-image-wrapper {
      transform-origin: left 35px;
      transform: scale(0.7);
    }
  }
`
const Menu = styled.ul<{ $visible: boolean }>`
  font-size: ${SIZES.medium}px;
  a {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.textColor};
    &.active {
      color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryHighlightedColor};
    }
  }
  @media (max-width: ${LAYOUT.tablet - 1}px) {
    background-color: ${COLORS.white};
    position: fixed;
    top: ${HEIGHT.mobile}px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    transform: translateX(${({ $visible }) => ($visible ? 0 : 100)}vw);
    transition: transform 300ms ease-in-out;
    border-top: 1px solid ${COLORS.bright};
    padding: 10px;
    li {
      a {
        display: inline-block;
        margin: 10px 0 0;
        padding: 10px;
      }
    }
  }
  @media (min-width: ${LAYOUT.tablet}px) {
    margin: 0 -30px 0 0;
    li {
      display: inline-block;
      a {
        display: block;
        line-height: 20px;
        padding: 30px;
      }
    }
  }
`

const Entry = ({ to, title }: { to: string; title: string }) => (
  <li>
    <Link to={to} activeClassName="active">
      {title}
    </Link>
  </li>
)

interface Data {
  assolib: {
    allDomains: Domain[]
  }
}

const Header: React.FC = () => {
  const { assolib } = useStaticQuery<Data>(graphql`
    query {
      assolib {
        allDomains {
          logo_url
          logo_image {
            childImageSharp {
              fixed(height: 60, quality: 90) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
          home_title
          outlink_url
          mission_visible_on_frontend
        }
      }
    }
  `)

  const domain = assolib.allDomains[0]
  const areMissionsVisible = domain.mission_visible_on_frontend ?? false

  const [openMenu, setOpenMenu] = useState(false)

  useEffect(() => {
    if (window.innerWidth < LAYOUT.tablet) {
      document.body.classList[openMenu ? "add" : "remove"]("noscroll")
    }
  }, [openMenu])

  return (
    <Container>
      <Content as="nav" role="navigation" aria-label="Menu">
        <Link to="/">
          <Image node={domain.logo_image} src={domain.logo_url} alt={domain.home_title} />
        </Link>
        <Hamburger $active={openMenu} onClick={() => setOpenMenu(!openMenu)} aria-label="Menu" />
        <Menu $visible={openMenu}>
          <Entry to="/" title="Accueil" />
          <Entry to="/associations/" title="Associations" />
          {areMissionsVisible && <Entry to="/benevolat/" title="Bénévolat" />}
          <li>
            <OutboundLink href={domain.outlink_url} target="_blank" rel="noopener">
              Votre ville
            </OutboundLink>
          </li>
        </Menu>
      </Content>
    </Container>
  )
}

export default Header
