import styled, { DefaultTheme, ThemeProps } from "styled-components"

const Button = styled.button<{ $active: boolean }>`
  position: absolute;
  z-index: 12;
  top: 9px;
  right: 15px;
  width: 40px;
  height: 32px;
  padding: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  @media (min-width: 1200px) {
    display: none;
  }
  span {
    display: block;
    position: absolute;
    height: 3px;
    background: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
    opacity: 1;
    left: 5px;
    right: 5px;
    width: 30px;
    transform-origin: left center;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  span:nth-of-type(1) {
    top: ${({ $active }) => ($active ? 3.5 : 5)}px;
    ${({ $active }) => ($active ? "transform: rotate(45deg); left: 9px;" : "")}
  }
  span:nth-of-type(2) {
    top: 14px;
    ${({ $active }) => ($active ? "width: 0%; opacity: 0;" : "")}
  }
  span:nth-of-type(3) {
    top: ${({ $active }) => ($active ? 24.5 : 23)}px;
    ${({ $active }) => ($active ? "transform: rotate(-45deg); left: 9px;" : "")}
  }
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $active: boolean
}

const Hamburger = (props: Props) => (
  <Button aria-expanded={props.$active} {...props}>
    <span />
    <span />
    <span />
  </Button>
)

export default Hamburger
