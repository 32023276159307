import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

const ellipsis = (str: string, maxLength = 150) => (str.length > maxLength ? str.substr(0, maxLength) + "…" : str)

export interface Props {
  title?: string
  noindex?: boolean
  description?: string
  image?: string
}

interface Data {
  assolib: {
    allDomains: Domain[]
  }
}

const SEO: React.FC<Props> = ({ title, noindex, description, image }) => {
  const { assolib } = useStaticQuery<Data>(graphql`
    query {
      assolib {
        allDomains {
          title
          seo_description
          seo_keywords
        }
      }
    }
  `)

  const domain = assolib.allDomains[0]
  const pageTitle = title ? `${title} | ${domain.title}` : domain.title

  const desc = description ? ellipsis(description) : domain.seo_description

  return (
    <Helmet htmlAttributes={{ lang: "fr" }}>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
      <meta name="description" property="og:description" content={desc} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="keywords" content={domain.seo_keywords} />
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:site_name" content={domain.title} />
      {image && <meta property="og:image" content={image} />}
      {noindex && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}

export default SEO
