import styled, { createGlobalStyle, DefaultTheme, ThemeProps, ThemeProvider } from "styled-components"
import { defaultTheme } from "../components/Theme"
import Footer from "./footer"
import Header from "./header"
import SEO, { Props as SEOProps } from "./seo"

const Main = styled.main`
  min-height: 50vh;
`

const GlobalCss = createGlobalStyle`
  body {
    font-family: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.fontFamily};
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.textColor};
  }
  h1,h2,h3 {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.textColor};
  }
  a {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  }
a:active {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryHighlightedColor};
  }
`

type LayoutProps = SEOProps & { theme?: DefaultTheme; domain?: Domain }

const Layout: React.FC<LayoutProps> = ({ children, theme, domain, ...props }) => (
  <ThemeProvider theme={theme || defaultTheme}>
    <GlobalCss />
    <SEO {...props} />
    <Header />
    <Main>{children}</Main>
    <Footer domain={domain} />
  </ThemeProvider>
)

export default Layout
